window.addEventListener("DOMContentLoaded", (event) => {
  $.fn.datetimepicker.dates['fr-FR'] = {
    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    daysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sept", "Oct", "Nov", "Déc"],
    today: "Aujourd'hui"
  };

  $('.js-datetimepicker').datetimepicker({
    language: 'fr-FR'
  });

  $('.js-datepicker').datetimepicker({
    language: 'fr-FR',
    pickTime: false
  });

  $('.toast').toast("show");

  if($('.js-rema-member-kind').length) {
    var loadingCurrentValue = $('.js-rema-member-kind').val();
    if(loadingCurrentValue) {
      $('.js-kind-fields:not(.js-' + loadingCurrentValue + ')').addClass('d-none');
      $('.js-' + loadingCurrentValue).removeClass('d-none');
    }

    $('.js-rema-member-kind').on('change', function(e) {
      var currentValue = $(this).val();
      $('.js-kind-fields:not(.js-' + currentValue + ')').addClass('d-none');
      $('.js-' + currentValue).removeClass('d-none');
    });
  }

  if($('.js-job-offer-kind input[type=checkbox]').length) {
    var checkbox = $('.js-job-offer-kind input[type=checkbox]');
    var checkboxChecked = checkbox.is(':checked');

    if(checkboxChecked) {
      $('.js-internal-job-offer-fields').removeClass('d-none');
      $('.js-external-job-offer-fields').addClass('d-none');
    } else {
      $('.js-internal-job-offer-fields').addClass('d-none');
      $('.js-external-job-offer-fields').removeClass('d-none');
    }

    checkbox.on('change', function(e) {
      var currentValue = checkbox.is(':checked');

      if(currentValue) {
        $('.js-internal-job-offer-fields').removeClass('d-none');
        $('.js-external-job-offer-fields').addClass('d-none');
      } else {
        $('.js-internal-job-offer-fields').addClass('d-none');
        $('.js-external-job-offer-fields').removeClass('d-none');
      }
    });
  }

  $('.js-table-line-link').each(function(index) {
    var tds = $(this).parents('tr.cursor-link').find('td:not(.actions)')
    var link = $(this).data('url');

    $(tds).on('click', function(e) {
      window.location = link;
    });
  });

  $('.nav-link[role=tab]').on('click', function(e) {
    let locale = $(e.currentTarget).data('locale');
    $('.js-locale-selector').val(locale);
  });
});
